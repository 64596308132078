<!-- System: STA
    Purpose: This compoment will display all the
              billing information like package, plan
              and prices.
-->

<template>
  <div>
    <v-container class="paymentmodel">
      <div class="unset-padding-at-345">
        <div class="row payment-methods ml-0 mr-0 justify-content-between">
          <div class="row align-items-center flex-xl-row-reverse">
            <div class="cardDetails col-md-12">
              <!-- Billing Plan Header -->
              <div class="row">
                <div class="col-md-12 plan-col pricing-main">
                  <h1 class="text-center card-title heading-plan">
                    Find the right plan for your team
                  </h1>
                  <h5 class="card-title sign-up-t">
                    Sign up for 7 days free trial. Get the plan that’s right
                    for<br />
                    your business or customise your own pricing plan.
                  </h5>
                  <div id="member-team" class="mydiv" v-if="active_team_members_list">
                    Total Number of Team members : {{ active_team_members_list.length }}
                  </div>

                  <div class="container-fluid bil-dur button-pricing">
                    <div class="">
                      <div class="plan-btn">
                        <div class="button-monthly">
                          <div
                            @click="
                              (cardDetail.stripe_plan = 'monthly_plan'),
                                (plainPeriod = 'month')
                            "
                            class="outerDiv1"
                          >
                            <div
                              :class="
                                cardDetail.stripe_plan === 'monthly_plan'
                                  ? 'innerDiv1'
                                  : ''
                              "
                            >
                              <div
                                :class="
                                  cardDetail.stripe_plan === 'monthly_plan'
                                    ? 'active'
                                    : ''
                                "
                                @click="
                                  (cardDetail.stripe_plan = 'monthly_plan'),
                                    (plainPeriod = 'month')
                                "
                              >
                                <div class="paymentText1">
                                  <div style="text-align: center">Monthly</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="button-monthly">
                          <div
                            @click="
                              (cardDetail.stripe_plan = 'yearly_plan'),
                                (plainPeriod = 'year')
                            "
                            class="outerDiv1"
                          >
                            <div
                              :class="
                                cardDetail.stripe_plan === 'yearly_plan'
                                  ? 'innerDiv1'
                                  : ''
                              "
                            >
                              <div
                                :class="
                                  cardDetail.stripe_plan === 'yearly_plan'
                                    ? 'active'
                                    : ''
                                "
                                @click="
                                  (cardDetail.stripe_plan = 'yearly_plan'),
                                    (plainPeriod = 'year')
                                "
                                class=""
                              >
                                <div class="paymentText1">
                                  <div class="year" style="text-align: center">
                                    Yearly
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Features Card For Plan -->
      <v-container>
        <v-row class="main-body">
          <v-col class="col-feature" lg="3" md="6" cols="12" xl="3">
            <div class="card card-feature main-feature">
              <div class="feature-body">
                <div class="price_updated">
                  <h2 class="card-title">Features</h2>
                </div>
                <ul class="card_list card_list_2">
                  <li>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <a span class="fake-link" v-bind="attrs" v-on="on"
                          >Companies</a
                        >
                      </template>
                      <span
                        >This is a company or business you will be using Staff
                        timer app for.</span
                      >
                    </v-tooltip>
                  </li>
                  <li>
                    <a>Projects</a>
                  </li>
                  <li>
                    <a span class="fake-link">Team Members</a>
                  </li>
                  <li>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <a span class="fake-link" v-bind="attrs" v-on="on"
                          >Time Tracking</a
                        >
                      </template>
                      <span
                        >Download the desktop app in order to track time spent
                        on tasks and projects</span
                      >
                    </v-tooltip>
                  </li>
                  <li>
                    <a span class="fake-link">Real Time Monitoring </a>
                  </li>
                  <li>
                    <a span class="fake-link">Timesheets</a>
                  </li>
                  <li>
                    <a span class="fake-link">Activities</a>
                  </li>
                  <li>
                    <a span class="fake-link">Work Video</a>
                  </li>
                  <li>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <a span class="fake-link" v-bind="attrs" v-on="on"
                          >Screenshots</a
                        >
                      </template>
                      <span
                        >You can set the time interval for screenshots starting
                        from 1 min.</span
                      >
                    </v-tooltip>
                  </li>
                  <li>
                    <a span class="fake-link">KeyStrokes-mouse activity</a>
                  </li>
                  <li>
                    <a span class="fake-link">Data Storage</a>
                  </li>
                </ul>
              </div>
            </div>
          </v-col>
          <!-- Basic Plan Details Card -->
          <v-col class="col-basic" lg="3" md="6" cols="12" xl="3">
            <div
              class="card card-basic card-feature b-card"
              style="box-shadow: 0 0 52px 0 rgba(186, 186, 186, 0.5)"
            >
              <div>
                <div class="price_updated_basic">
                  <h2 class="card-title">Basic</h2>
                  <div class="col-sm-6 col-md-6 col-xl-4 mt-3 min-content">
                    <div
                      @click="cardDetail.stripe_plan = 'monthly_plan'"
                      class="outerDivbasic"
                    >
                      <div
                        :class="
                          cardDetail.stripe_plan === 'monthly_plan'
                            ? 'innerDiv'
                            : ''
                        "
                      >
                        <div
                          :class="
                            cardDetail.stripe_plan === 'monthly_plan'
                              ? 'active'
                              : ''
                          "
                          @click="cardDetail.stripe_plan = 'monthly_plan'"
                        >
                          <div>
                            <div class="font-weight-bold">
                              {{ cardDetail.stripe_plan | getPlanName }}
                            </div>
                            <div>
                              <span
                                :class="{
                                  'small-not-active':
                                    cardDetail.stripe_plan !== 'monthly_plan',
                                }"
                                class="small"
                                style="text-align: center"
                                ><b class="smalltext"
                                  >&dollar;{{ basic_amount }}</b
                                >
                                / member</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="card_list" style="text-align: center">
                  <li>
                    <span class="fake-link">1</span>
                  </li>
                  <li>
                    <span class="fake-link">5</span>
                  </li>
                  <li>
                    <span class="fake-link">10 members</span>
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="green" dense
                        >mdi-check</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="red" dense
                        >mdi-close</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="green" dense
                        >mdi-check</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="green" dense
                        >mdi-check</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="green" dense
                        >mdi-check</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link">Optional</span>
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="red" dense
                        >mdi-close</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link">1.5 Months</span>
                  </li>
                </ul>
                <!-- Basic Plan Subscribe Button -->
                <v-btn
                  @click="openModal('basic')"
                  class="btn btn-primary btn-basic"
                  type="button"
                  color="#021479"
                >
                  Subscribe Now
                </v-btn>
              </div>
            </div>
          </v-col>
          <!-- Standard Plan Details Card -->
          <v-col class="col-basic" lg="3" md="6" cols="12" xl="3">
            <div
              class="card card-feature b-card"
              style="box-shadow: 0 0 52px 0 rgba(186, 186, 186, 0.5)"
            >
              <div class="">
                <div class="price_updated_standard">
                  <h2 class="card-title" style="padding-right: 21px !important">
                    Standard
                  </h2>
                  <div
                    @click="cardDetail.stripe_plan = 'yearly_plan'"
                    class="outerDivbasic"
                  >
                    <div
                      :class="
                        cardDetail.stripe_plan === 'yearly_plan'
                          ? 'innerDiv'
                          : ''
                      "
                    >
                      <div
                        :class="
                          cardDetail.stripe_plan === 'yearly_plan'
                            ? 'active'
                            : ''
                        "
                        @click="cardDetail.stripe_plan = 'yearly_plan'"
                        class=""
                      >
                        <div>
                          <div class="font-weight-bold">
                            {{ cardDetail.stripe_plan | getPlanName }}
                          </div>
                          <div>
                            <span
                              :class="{
                                'small-not-active':
                                  cardDetail.stripe_plan !== 'yearly_plan',
                              }"
                              class="small"
                              ><b class="smalltext"
                                >&dollar;{{ standard_amount }}</b
                              >
                              / member</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <ul class="card_list" style="text-align: center">
                  <li>
                    <span class="fake-link">Unlimited</span>
                  </li>
                  <li>
                    <span class="fake-link">10</span>
                  </li>
                  <li>
                    <span class="fake-link">50 members</span>
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="green" dense
                        >mdi-check</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="green" dense
                        >mdi-check</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="green" dense
                        >mdi-check</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="green" dense
                        >mdi-check</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="green" dense
                        >mdi-check</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link">Optional</span>
                  </li>
                  <li>
                    <span class="fake-link"
                      ><v-icon aria-hidden="true" color="red" dense
                        >mdi-close</v-icon
                      ></span
                    >
                  </li>
                  <li>
                    <span class="fake-link">03 Months</span>
                  </li>
                </ul>
                <!-- Standard Plan Subscribe Button -->
                <v-btn
                  @click="openModal('standard')"
                  class="btn btn-primary btn-basic"
                  type="button"
                  color="#021479"
                >
                  Subscribe Now
                </v-btn>
              </div>
            </div>
          </v-col>
          <!-- Premium Plan  Details -->
          <v-col class="col-basic" lg="3" md="6" cols="12" xl="3">
            <div
              class="card card-feature b-card"
              style="box-shadow: 0 0 52px 0 rgba(186, 186, 186, 0.5)"
            >
              <div>
                <div class="price_updated_premium">
                  <h2 class="card-title">Premium</h2>
                  <div style="text-align: center" v-if="isPremium">
                    <div class="font-weight-bold">
                      {{ cardDetail.stripe_plan | getPlanName }}
                    </div>
                    <div>
                      <span
                        :class="{
                          'small-not-active':
                            cardDetail.stripe_plan !== 'yearly_plan',
                        }"
                        class="small"
                        ><b class="smalltext">&dollar;{{ premium_amount }}</b> /
                        member</span
                      >
                    </div>
                  </div>
                  <h5
                    class="font-weight-bold"
                    style="text-align: center"
                    v-else
                  >
                    Get a Quote
                  </h5>
                </div>

                <ul class="card_list" style="text-align: center">
                  <li>
                    <div v-if="companies_billings_value.companies">
                      {{ companies_billings_value.companies }}
                    </div>
                    <div v-else>
                      <span class="fake-link">Unlimited</span>
                    </div>
                  </li>
                  <li>
                    <div v-if="companies_billings_value.projects">
                      {{ companies_billings_value.projects }}
                    </div>
                    <div v-else>
                      <span class="fake-link">Unlimited</span>
                    </div>
                  </li>
                  <li>
                    <span class="fake-link">Unlimited</span>
                  </li>
                  <li>
                    <div
                      v-if="
                        companies_billings_value.time_tracking_allowed === true
                      "
                    >
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                    <div
                      v-else-if="
                        companies_billings_value.time_tracking_allowed === false
                      "
                    >
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                    <div v-else>
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                  </li>
                  <li>
                    <div
                      v-if="companies_billings_value.real_time_allowed === true"
                    >
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                    <div
                      v-else-if="
                        companies_billings_value.real_time_allowed === false
                      "
                    >
                      <span class="fake-link"><i class="fa fa-times"></i></span>
                    </div>
                    <div v-else>
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                  </li>

                  <li>
                    <div>
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                  </li>

                  <li>
                    <div>
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                  </li>

                  <li>
                    <div>
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                  </li>
                  <li>
                    <div
                      v-if="
                        companies_billings_value.screenshots_allowed === true
                      "
                    >
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                    <div
                      v-else-if="
                        companies_billings_value.screenshots_allowed === false
                      "
                    >
                      <span class="fake-link"><i class="fa fa-times"></i></span>
                    </div>
                    <div v-else>
                      <span class="fake-link">optional</span>
                    </div>
                  </li>
                  <li>
                    <div
                      v-if="
                        companies_billings_value.keystrokes_mouse_activity_allowed ===
                        true
                      "
                    >
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                    <div
                      v-else-if="
                        companies_billings_value.keystrokes_mouse_activity_allowed ===
                        false
                      "
                    >
                      <span class="fake-link"><i class="fa fa-times"></i></span>
                    </div>
                    <div v-else>
                      <span class="fake-link"
                        ><v-icon aria-hidden="true" color="green" dense
                          >mdi-check</v-icon
                        ></span
                      >
                    </div>
                  </li>
                  <li>
                    <div v-if="companies_billings_value.data_storage === '30'">
                      <span class="fake-link">30 Days</span>
                    </div>
                    <div
                      v-else-if="companies_billings_value.data_storage === '1'"
                    >
                      <span class="fake-link">1 Year</span>
                    </div>
                    <div v-else>
                      <span class="fake-link"> On-premise deployment</span>
                    </div>
                  </li>
                </ul>
                <!-- Premium Plan Subscribe Button -->
                <v-btn
                  @click="openModal('premium')"
                  class="btn btn-primary btn-basic"
                  type="button"
                  v-if="isPremium"
                  color="#021479"
                >
                  Subscribe Now
                </v-btn>
                <!-- Premium Plan Contact Sales Button -->
                <v-btn
                  @click="contactSales()"
                  class="btn btn-primary btn-basic"
                  type="button"
                  color="#021479"
                  v-else
                >
                  Contact sales
                </v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!-- Select Payment Type Option Modal i.e Stripe Or Paypal -->
    <v-dialog v-model="paymentOptionModal" width="500">
      <v-card id="payment-option" ref="payment_option" class="payment_option">
        <v-card-title class="headline white lighten-2 justify-center">
          Select Payment Option
          <v-spacer></v-spacer>
          <v-btn icon @click="paymentOptionModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <div class="d-flex" style="height: 111px;">
            <v-card
                class="mx-auto mt-1"
                max-width="344"
                style="height:100px"
            >
                <img
                    @click="
              (showstripeMark = true),
                (showpayPalMark = false),
                selectPayment('stripe')
            "
                    src="../../assets/images/stripe.png"
                    style="margin-right: 80px; margin-left: 20px; cursor: pointer"
                    width="120px"
                    height="100px"
                    class="img-width"
                />
            </v-card>
            <v-card
                class="d-none mx-auto mt-1"
                max-width="344"
                style="height:100px"
            >
             <img
                 @click="
              (showstripeMark = false),
                (showpayPalMark = true),
                selectPayment('paypal')
            "
                 class="img-width"
                 src="../../assets/images/paypal.jpeg"
                 style="margin-right: 50px; cursor: pointer"
                 width="150px"
                 height="100px"
             />
            </v-card>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PaymentMethodMixin from "@/components/Billing/PaymentMethodMixin";
import { mapState } from "vuex";

export default {
  data() {
    return {
      paymentOptionModal: false,
      showstripeMark: false,
      showpayPalMark: false,
      plainPeriod: "year",
    };
  },
  name: "billing_info",
  components: { CreditCard: () => import("@/components/Billing/CreditCard") },
  mixins: [PaymentMethodMixin],
  computed: {
    ...mapState("custom", ["companies_list", "team_members","active_team_members_list"]),
    selectedCompany() {
      let company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) {
        return company;
      } else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    check_subscription() {
      return this.$store.state.custom.company_subscription_status;
    },
    basic_amount() {
      return this.cardDetail.stripe_plan === "monthly_plan" ? "3.99" : "39.99";
    },

    standard_amount() {
      return this.cardDetail.stripe_plan === "monthly_plan" ? "6.99" : "59.99";
    },
    isPremium() {
      return (
        this.selectedCompany.monthly_amount &&
        this.selectedCompany.yearly_amount &&
        !(
          ["3.99", "6.99"].includes(this.selectedCompany.monthly_amount) ||
          ["39.99", "59.99"].includes(this.selectedCompany.yearly_amount)
        )
      );
    },
    premium_amount() {
      return this.cardDetail.stripe_plan === "monthly_plan"
        ? this.selectedCompany.monthly_amount
        : this.selectedCompany.yearly_amount;
    },
    companies_billings_value() {
      return this.selectedCompany;
    },
  },
  filters: {
    getPlanName(plan) {
      return plan
        .substring(0, plan.length - 5)
        .replace(/^\w/, (c) => c.toUpperCase());
    },
  },
  mounted() {
    if (
      this.$route.query.token ||
      this.$route.query.subscription_id ||
      this.$route.query.ba_token
    ) {
      this.$root.$emit("snack_bar", {
        show: true,
        message: "Subscription Cancelled",
        snackbarColor: "red",
      });
      this.$router.push({
        name: "Billing",
        params: { id: this.$route.params.id },
      });
    }
    this.$store.dispatch("custom/get_team_members", {
      company_id: this.$route.params.id,
    });
    this.$store.dispatch("custom/getAllCompaniesList");
  },
  methods: {
    /**
     * This function is resposible for
     * open payment modal option i.e
     * stripe or paypal
     */
    openModal(product) {
      this.product = product;
      this.paymentOptionModal = true;
    },
    /**
     * This function is resposible for
     * payment update if paypal method is
     * selected then if condion will work
     * else condition will work for stripe
     */
    selectPayment(payment) {
      if (payment == "paypal") {
        if (this.plainPeriod == "month") {
          if (this.product == "basic") {
            var data = {
              company_id: this.$route.params.id,
              plan_type: this.paypalPackage.monthly_basic,
            };
          }
          if (this.product == "standard") {
            var data = {
              company_id: this.$route.params.id,
              plan_type: this.paypalPackage.monthly_standard,
            };
          }
        }
        if (this.plainPeriod == "year") {
          if (this.product == "basic") {
            var data = {
              company_id: this.$route.params.id,
              plan_type: this.paypalPackage.yearly_basic,
            };
          }
          if (this.product == "standard") {
            var data = {
              company_id: this.$route.params.id,
              plan_type: this.paypalPackage.yearly_standard,
            };
          }
        }
        this.$store.commit("custom/toggle_loader", true);
        this.$store
          .dispatch("custom/paypalSubscriptionCreate", data)
          .then((response) => {
            this.$store.commit("custom/toggle_loader", false);
            this.$store.dispatch("custom/getAllCompaniesList");
            let route = response.data.links["0"];
            window.open(route.href, "_blank");
          })
          .catch((error) => {
            this.$store.commit("custom/toggle_loader", false);
          });
      } else {
        let product = this.product;
        if (this.check_subscription === false) {
          this.$root.$emit("selectedPlan", {
            plan: this.cardDetail.stripe_plan,
            product: product,
            amount: this[product + "_amount"],
          });
        } else {
          this.update_payment_card();
        }
      }
    },
    /**
     * This function is resposible for
     * to move user from billing page
     * to contact Sales page
     */

    contactSales() {
      window.open("https://stafftimerapp.com/contact-us/", "_blank");
    },
    /**
     * This function is resposible for
     * Stripe Subscription Plan Update
     */
    update_payment_card() {
      if (this.plainPeriod == "month") {
        if (this.product == "basic") {
          var data = {
            company_id: this.$route.params.id,
            stripe_plan: "monthly_plan",
            stripe_product: "basic",
          };
        }
        if (this.product == "standard") {
          var data = {
            company_id: this.$route.params.id,
            stripe_plan: "monthly_plan",
            stripe_product: "standard",
          };
        }
      }
      if (this.plainPeriod == "year") {
        if (this.product == "basic") {
          var data = {
            company_id: this.$route.params.id,
            stripe_plan: "yearly_plan",
            stripe_product: "basic",
          };
        }
        if (this.product == "standard") {
          var data = {
            company_id: this.$route.params.id,
            stripe_plan: "yearly_plan",
            stripe_product: "standard",
          };
        }
      }
      this.$store.commit("custom/toggle_loader", true);
      this.$store
        .dispatch("custom/update_payment_card", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error) {
            // let errorElement = document.getElementById(this.errorElementId);
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "red",
            });
            this.$store.dispatch("custom/getAllCompaniesList");
            this.paymentOptionModal = false;
            this.createSetupIntent();
          } else {
            this.$root.$emit("snack_bar", {
              show: true,
              message: response.data.error_msg,
              snackbarColor: "green",
            });
            this.$store.dispatch("custom/company_payments_cards", {
              company_id: this.$route.params.id,
            });
            this.$store.dispatch("custom/getAllCompaniesList");
            this.paymentOptionModal = false;
          }
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          this.paymentOptionModal = false;
          let expire_year = error.response.data.errors["expiry_year"];
          if (expire_year) {
            this.$root.$emit("snack_bar", {
              show: true,
              message: expire_year,
              snackbarColor: "red",
            });
          } else {
            this.$root.$emit("snack_bar", {
              show: true,
              message: "Something want wrong Please try again!",
              snackbarColor: "red",
            });
          }
        });
    },
  },
};
</script>

<style scoped>
/******pricing-button********/
.btn-white:hover {
  background-color: white !important;
}
.btn-white:focus {
  outline: none !important;
  border: 0px !important;
}
.heading-plan {
  font-size: 32px !important;
  line-height: 50px !important;
  font-weight: 600;
  margin-bottom: 0px !important;
}
.sign-up-t {
  font-size: 18px !important;
  line-height: 31px;
  margin-top: 26px !important;
}
div#member-team {
  width: 390px !important;
}
.pricing-main .button-pricing {
  width: 315px !important;
  padding: 0 0px !important;
  display: block;
  text-align: center !important;
  margin: 0 auto 38px !important;
}
.pricing-main .button-pricing .paymentText1 {
  cursor: pointer;
  width: 100%;
  padding: 5px 0;
  margin: 0 !important;
  text-align: center;
}
.pricing-main .button-pricing .year {
  padding-left: 0px;
}
.pricing-main .button-pricing .outerDiv1,
.pricing-main .button-pricing .innerDiv1 {
  width: 100% !important;
}
.button-monthly {
  width: 136px;
  float: left;
}
.button-pricing .plan-btn {
  background: #fff;
  display: inline-block;
  padding: 12px 0 6px !important;
}
/******pricing-button********/
.container-fluid {
  box-shadow: 0 8px 31px 0 #bcbcbc;
  font-weight: bold;
  background-color: white;
  border-radius: 3px;
  width: 25%;
  padding-right: 0px !important;
  padding-left: 15px;
  margin: 0 auto 36px !important;
}

.col-feature {
  height: inherit;
  max-width: 18rem;
  margin-bottom: 3rem;
}

.card-feature {
  height: 100%;
}

.col-basic {
  height: max-content;
  max-width: 14rem;
  margin-bottom: 3rem;
}

.price_updated {
  margin-bottom: 16px;
  height: 150px;
  width: auto;
  background-color: #021479;
}

.price_updated_basic {
  height: 150px;
  width: auto;
  background-color: #b7ecff;
  margin-bottom: 16px;
}

.price_updated_standard {
  margin-bottom: 16px;
  height: 150px;
  width: auto;
  background-color: #ffe692;
  opacity: 0.8;
}

.price_updated_premium {
  margin-bottom: 16px;
  height: 150px;
  width: auto;
  background-color: #b8ffd4;
}

.price_updated h2 {
  margin: auto;
  padding-top: 63px !important;
  height: auto;
  width: 212px;
  color: #fff;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 25px;
  text-align: center !important;
  text-transform: uppercase;
}

.price_updated_basic h2 {
  margin: auto;
  padding: 32px 12px 3px 0px !important;
  height: auto;
  width: 212px;
  color: #021479;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 25px;
  text-align: center !important;
}

.price_updated_standard h2 {
  margin: auto;
  padding: 32px 0px 21px 0px !important;
  height: auto;
  width: 212px;
  color: #021479;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 25px;
  text-align: center !important;
}

.price_updated_premium h2 {
  margin: auto;
  padding: 32px 14px 21px 0px !important;
  height: auto;
  width: 212px;
  color: #021479;
  font-size: 34px;
  font-weight: 600;
  letter-spacing: 0.25px;
  line-height: 25px;
  text-align: center !important;
}

.card_list_2 {
  padding-left: 20px !important;
}

.card_list {
  padding-left: 0;
}

.card_list li {
  display: block;
}

.card_list a {
  text-decoration: none !important;
  line-height: 14px;
  color: #0002a8;
  font-size: 14px;
  font-weight: bold;
}

.outerDiv {
  text-align: center;
  float: left;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 3px;
}

.outerDiv1 {
  cursor: pointer;
  float: left;
  margin-bottom: 1px;
  border-radius: 3px;
  width: 126%;
}

.outerDivbasic {
  text-align: center;
}

.innerDiv {
  width: 100%;
  border-radius: 2px;
}

.innerDiv1 {
  width: 104%;
  background-color: #0002a8;
  color: white;
  border-radius: 2px;
}

.small {
  font-weight: 400;
  white-space: nowrap;
}

.small b {
  font-weight: 500;
}

.feature-body {
  height: inherit;
  box-shadow: 0 0 52px 0 rgba(186, 186, 186, 0.5);
}

.cardDetails {
  padding-left: 45px;
  padding-right: 45px;
}

.plan-col h4 {
  color: #36454f;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 25px;
  font-family: "Poppins", "sans-serif";
  margin-bottom: 4px !important;
}

.plan-col p {
  color: #a7bbdb;
  font-family: "Poppins", "sans-serif";
  font-size: 13px;
  line-height: 18px;
}

.font-weight-bold {
  line-height: 29px;
  font-weight: 800 !important;
}

/*class billing-info css*/
.card-title {
  font-family: "poppins" !important;
  color: #021479;
  text-align: center !important;
  margin-top: 30px;
  margin-bottom: 36px;
}

.paymentmodel {
  margin-bottom: 20px;
}

.flex-xl-row-reverse {
  width: 100%;
}

.min-content {
  max-width: min-content !important;
}

.col-plan-btn {
  margin-top: 2px;
  padding-left: 3px;
}

.main-body {
  margin-left: 5%;
}

.paymentText1 {
  cursor: pointer;
  width: 108px;
  padding: 5px;
}

.smalltext {
  margin-top: 20px;
  padding-left: 20px;
  font-weight: bold;
  margin-left: 0px !important;
  color: #0002a8;
  font-family: "poppins" !important;
  text-align: left !important;
  font-size: 24px;
  line-height: 10px;
}

.fa-check {
  color: green !important;
  font-size: 12px !important;
}

.fa-times {
  color: red !important;
  font-size: 12px !important;
}

.btn-basic {
  padding-top: 6px;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  background-color: #021479;
  box-shadow: 0 3px 9px 0 #e9e9e9;
}

.year {
  padding-left: 23px;
}

.bil-dur {
  width: 30%;
}

.btn-primary {
  color: #fff;
}

/* media query */
@media (max-width: 980px) {
  .col-feature,
  .col-basic {
    max-width: 50%;
  }
}
@media (max-width: 767px) {
  .col-feature,
  .col-basic {
    margin-bottom: 0px !important;
  }
  .CardDiv {
    display: none;
  }
}

@media (max-width: 575px) {
  .yearLabel input.card-field {
    margin-top: 0px;
  }

  .mt-5 {
    margin-top: 1rem !important;
  }
}

@media (max-width: 410px) {
  .plan-col h4 {
    font-size: 15px;
  }

  .plan-col p {
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .cardDetails {
    padding-left: 0px;
    padding-right: unset;
  }
}

@media (max-width: 539px) {
  .plan-btn {
    justify-content: center;
  }
}

@media (max-width: 345px) {
  .unset-padding-at-345 {
    padding-right: unset;
    padding-left: unset;
  }
}

@media (max-width: 800px) and (min-width: 700px) {
  .container-fluid {
    width: 37.1vw;
    margin-left: 27% !important;
  }

  .container {
    max-width: 100%;
  }

  .card-feature {
    min-height: 0vh !important;
  }

  .mydiv {
    color: #2758f6;
    font-weight: 600;
    background-color: #cbdaf2;
    border-radius: 22px;
    font-size: 15px;
    height: 39px;
    text-align: center;
    font-family: "poppins" !important;
    padding-top: 7px;
    width: 70%;
    margin: 0 auto 3% !important;
  }

  /* .card-title {
      margin-left : 17%;
    } */
}

@media (max-width: 1100px) and (min-width: 1000px) {
  .mydiv {
    color: #2758f6;
    font-weight: 600;
    background-color: #cbdaf2;
    border-radius: 22px;
    font-size: 15px;
    -webkit-box-shadow: 5px 10px #888888;
    box-shadow: 2px 4px #888888;
    margin-bottom: 2%;
    margin-left: 35%;
    height: 39px;
    text-align: center;
    font-family: "poppins" !important;
    padding-top: 7px;
    width: 42%;
  }

  .container {
    min-width: max-content;
  }

  .container-fluid {
    width: 28.1vw;
    margin-left: 34% !important;
  }

  .main-body {
    margin-left: -6% !important;
  }

  .col-basic {
    max-width: 18.6vw !important;
  }

  .col-feature {
    max-width: 18rem !important;
  }

  .feature-body {
    min-height: 0vh !important;
    width: 100%;
  }

  .card-feature {
    min-height: 0vh !important;
  }

  .smalltext {
    padding-left: 0px !important;
    font-size: 20px;
  }

  .price_updated_basic h2 {
    width: 100px !important;
    font-size: 20px;
  }

  .price_updated_standard h2 {
    padding-right: 55px !important;
    font-size: 20px;
  }

  .price_updated h2 {
    width: 100px !important;
    font-size: 18px;
  }

  .price_updated_premium h2 {
    padding-right: 55px !important;
    font-size: 20px;
  }

  .card_list_2 {
    padding-left: 5px !important;
  }

  .outerDivbasic {
    margin-left: 0;
  }

  .card_list a {
    font-size: 12px !important;
    line-height: 30px;
  }
}

@media (max-width: 2600px) and (min-width: 2100px) {
  .innerDiv1 {
    padding-right: 0;
  }

  .main-feature {
    width: 264px;
  }

  .container {
    margin-left: 15vw;
  }

  .container-fluid {
    width: 11.1vw;
  }

  /* .feature-body {
      min-height : 80vh !important
    } */
  .mydiv {
    color: #2758f6;
    font-weight: 600;
    background-color: #cbdaf2;
    border-radius: 22px;
    font-size: 15px;
    margin-bottom: 36px;
    margin-left: 36%;
    height: 39px;
    width: 15%;
    text-align: center;
    font-family: "poppins" !important;
    padding-top: 7px;
  }
}

@media (max-width: 2000px) and (min-width: 1100px) {
  .main-feature {
    width: 264px;
  }
}

@media (max-width: 450px) and (min-width: 380px) {
  .col-basic {
    max-width: 18rem;
  }

  .card {
    width: 94% !important;
    margin-left: -2%;
  }

  .mydiv {
    color: #2758f6;
    font-weight: 400;
    background-color: #cbdaf2;
    border-radius: 22px;
    font-size: 15px;
    margin-bottom: 5%;
    margin-left: 2%;
    height: 39px;
    text-align: center;
    font-family: "poppins" !important;
    padding-top: 7px;
    width: 94% !important;
  }

  .outerDiv1 {
    cursor: pointer;
    float: left;
    margin-bottom: 1px;
    border-radius: 3px;
    width: 106%;
  }

  .container-fluid {
    margin-left: 7% !important;
    width: 63vw;
  }

  .col-plan-btn {
    margin-top: 2px;
    padding-left: 0px !important;
  }

  .swtich {
    width: 96%;
  }
}

@media (max-width: 375px) and (min-width: 349px) {
  .col-basic {
    max-width: 18rem;
  }

  .card {
    width: 100% !important;
    margin-left: -6% !important;
  }

  .mydiv {
    color: #2758f6;
    font-weight: 600;
    background-color: #cbdaf2;
    border-radius: 22px;
    font-size: 15px;
    margin-bottom: 5%;
    margin-left: -9%;
    height: 39px;
    text-align: center;
    font-family: "poppins" !important;
    padding-top: 7px;
    width: 121%;
  }

  .price_updated h2 {
    width: -1px !important;
    font-size: 27px;
  }

  .price_updated_basic h2 {
    width: 185px !important;
  }

  .smalltext {
    font-size: 14px;
  }

  .price_updated_standard h2 {
    width: 185px !important;
    font-size: 26px;
  }

  .price_updated_premium h2 {
    width: 185px !important;
    font-size: 26px;
  }

  .container-fluid {
    -webkit-box-shadow: 0 8px 31px 0 #bcbcbc;
    box-shadow: 0 8px 31px 0 #bcbcbc;
    font-weight: bold;
    background-color: white;
    border-radius: 3px;
    padding-right: 0px !important;
    padding-left: 15px;
    margin-right: 0px !important;
    margin-left: -1% !important;
    margin-bottom: 36px;
    width: auto;
  }

  .swtich {
    width: 90%;
  }

  .innerDiv1[data-v-b5be02b0] {
    width: 100% !important;
  }

  .plan-btn {
    width: max-content !important;
  }
}

@media (max-width: 320px) and (min-width: 301px) {
  .card {
    width: 115% !important;
    margin-left: -3% !important;
  }

  .mydiv {
    color: #2758f6;
    font-weight: 600;
    background-color: #cbdaf2;
    border-radius: 22px;
    font-size: 15px;
    margin-bottom: 16%;
    margin-left: -4%;
    height: 56px;
    text-align: center;
    font-family: "poppins" !important;
    padding-top: 7px;
    width: 107%;
  }

  .card-feature {
    background-color: transparent;
    border: none;
  }

  .plan-btn {
    width: max-content;
  }

  .innerDiv1 {
    width: 80%;
  }
}

@media (max-width: 1700px) and (min-width: 1500px) {
  .outerDiv1 {
    width: 164%;
  }

  .card-feature {
    min-height: 62vh !important;
  }

  .feature-body {
    min-height: 62vh !important;
  }

  .paymentText1 {
    width: 113px;
  }
}
</style>
<style>
.mydiv {
  font-weight: 600;
  background-color: #cbdaf2;
  border-radius: 22px;
  font-size: 15px;
  color: #2758f6;
  height: 39px;
  width: 29%;
  text-align: center;
  font-family: "poppins" !important;
  padding-top: 7px;
  margin: 0 auto 36px !important;
}

.popover-body {
  max-width: 200px;
  padding: 8px 8px !important;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.bs-popover-auto[x-placement^="right"] > .arrow::after,
.bs-popover-right > .arrow::after {
  border-right-color: black;
}

a:hover {
  color: black !important;
}

@media (max-width: 400px) and (min-width: 301px) {
  .popover-body {
    max-width: 130px !important;
  }
}

@media (max-width: 1490px) and (min-width: 1200px) {
  .container {
    min-width: 92% !important;
  }
}
@media (max-width: 2800px) and (min-width: 2560px) {
  .main-body {
    margin-left: 12%;
  }
}
@media (max-width: 1200px) {
  .paymentmodel .mydiv {
    height: 39px;
    width: 38%;
  }
  .paymentmodel .container-fluid {
    width: 31%;
  }
  .main-body {
    margin-left: 0% !important;
  }
}
@media (max-width: 1080px) {
  .paymentmodel .mydiv {
    width: 44%;
  }
  .paymentmodel .container-fluid {
    width: 36%;
  }
  .price_updated_basic .min-content {
    margin: 0 auto;
  }
}
@media (max-width: 850px) {
  .paymentmodel .mydiv {
    width: 58%;
  }
  .paymentmodel .container-fluid {
    width: 49%;
  }
  .price_updated_basic .min-content {
    margin: 0 auto;
  }
  .col-basic {
    max-width: 50% !important;
  }
}
@media (max-width: 768px) {
  .paymentmodel .container-fluid {
    width: 56%;
  }
}
@media (max-width: 767px) {
  .paymentmodel .mydiv {
    width: 71%;
  }
  .paymentmodel .container-fluid {
    width: 44%;
    padding: 0;
    margin: 0 auto 36px !important;
  }
}
@media (max-width: 601px) {
  .col-feature,
  .col-basic {
    max-width: 100% !important;
  }
  .heading-plan {
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 14px !important;
  }
  .price_updated_standard h2 {
    padding-right: 0px;
  }
  .main-feature .card_list {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .sign-up-t br {
    display: none;
  }
  .pricing-main .sign-up-t {
    font-size: 15px !important;
    line-height: 29px;
  }
}
@media (max-width: 480px) {
  .pricing-main div#member-team {
    width: 100% !important;
  }
  .paymentmodel .mydiv {
    height: auto;
    width: 100%;
    font-size: 14px;
    padding: 11px 0;
  }
  .heading-plan {
    font-size: 25px;
    line-height: 40px;
  }
  .paymentmodel .card {
    width: 100% !important;
    margin-left: 0% !important;
  }
  .paymentmodel .container-fluid {
    width: 79%;
  }
  .price_updated h2 {
    font-size: 25px !important;
  }
}
@media (max-width: 375px) {
  .cardDetails .pricing-main .button-pricing {
    width: 100% !important;
  }
  .paymentmodel .container-fluid {
    width: 92%;
  }
  .year {
    padding-left: 0 !important;
  }
  .heading-plan {
    font-size: 23px;
    line-height: 35px;
  }
  .price_updated_basic h2,
  .price_updated_standard h2,
  .price_updated_premium h2 {
    font-size: 25px !important;
  }
  .price_updated_standard h2 {
    padding-right: 0;
  }
  .col-plan-btn {
    padding-right: 3px !important;
  }
}
@media (max-width: 320px) {
  .paymentmodel .container-fluid {
    width: 100%;
  }
  .year {
    padding-left: 0 !important;
  }
}
.fake-link {
  color: #0002a8;
  /* text-decoration: underline; */
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}
@media (max-width: 319px) {
  .button-monthly {
    width: 111px !important;
  }
  .paymentmodel .mydiv {
    font-size: 12px;
  }
  .price_updated h2,
  .price_updated_basic h2 {
    width: 100% !important;
  }
  .heading-plan {
    font-size: 26px !important;
    line-height: 43px !important;
  }
  .smalltext {
    margin-top: 20px;
    padding-left: 0px !important;
  }
}
@media (max-width: 414px) {
  div#payment-option___BV_modal_content_ {
    height: 230px;
    width: 300px !important;
    margin-top: 200px;
  }
  .img-width {
    width: 80px !important;
    height: 80px !important;
  }
}
div#payment-option___BV_modal_content_ {
  height: 230px;
  width: 400px;
  margin-top: 200px;
}
</style>
